<template>
    <div class="page-container">
      <div class="page-route-select">
        <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
          Home
        </router-link>
        <span class="material-icons page-route-select-icon">play_arrow</span>
        <router-link to="/fornecedores" class="page-route-select-text">
          Fornecedores
        </router-link>
        <span class="material-icons page-route-select-icon">play_arrow</span>
        <div class="page-route-select-text">
          Produtos
        </div>
      </div>
      <div class="page-title">
        Produtos
      </div>
      <div class="orders-page__top-actions-container">
        <router-link to="/fornecedores">
            <Button :iconName="'arrow_back'" title="Voltar" :text="'Voltar'" :action="() => {}" />
        </router-link>
      </div>
      <div class="orders-page__actions-container">
        <StandardInput :placeholder="'Digite o nome'" 
                       :title="'Nome'" 
                       :action="setNameValue" 
                       class="orders-page__content-inputs"
                       :type="'text'"/>
        <StandardInput :placeholder="'Selecione um fornecedor'" 
                       :title="'Fornecedor'"
                       :type="'select'"
                       :list="providersList"
                       :action="setProviderValue" 
                       class="orders-page__content-inputs"/>
        <div>
          <div v-if="!productsWithoutCategoriesIsChecked" @click="filterProductsWithoutCategories" class="lastbuydate_wrapper">
            <label class="products-page__checkbox-wrapper">
              <span class="lastbuydate_title">Produtos sem categoria</span>
              <input type="checkbox">
              <span class="checkmark"></span>
            </label>
          </div>
          <div v-if="productsWithoutCategoriesIsChecked" @click="removeProductsWithoutCategories" class="lastbuydate_wrapper">
            <label class="products-page__checkbox-wrapper">
              <span class="lastbuydate_title">Produtos sem categoria</span>
              <input type="checkbox" checked>
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <Button style="margin-top: 18px;" :iconName="'delete'" title="Limpar Filtros" :text="'Limpar Filtros'" :action="() => { filters={}; load(); productsWithoutCategoriesIsChecked = false;}" />
      </div>
      <div class="page-selected-filters-container">
	      <div v-if="this.filters.name" class="page-selected-filters">
          <div class="page-selected-filters-text">{{ filters.name }}</div>
          <span @click="setNameValue('')" class="material-icons page-selected-filters-icon">close</span>
        </div>
        <div v-if="this.filters.provider.name" class="page-selected-filters">
          <div class="page-selected-filters-text">{{ filters.provider.name }}</div>
          <span @click="setProviderValue('')" class="material-icons page-selected-filters-icon">close</span>
        </div>
      </div>
      <cc-loader v-show="is_loading" />
      <no-data-available v-if="!is_loading && products.length == 0" :text="'Nenhum produto encontrado'" />
      <div v-if="products.length > 0 && !is_loading" class="page-table-container">
        <div>
          <div class="page-table-header">
            <div class="page-table-header-text img-width"></div>
            <div class="page-table-header-text id-width">Código Ean</div>
            <div class="page-table-header-text name-width">Nome</div>
            <div class="page-table-header-text package-width">Embalagem</div>
            <div class="page-table-header-text category-width">Categoria</div>
            <div class="page-table-header-text provider-width">Fornecedor</div>
            <div class="page-table-header-text status-width">Status</div>
            <div class="page-table-header-text actions-width">Ações</div>
          </div>
          <div v-for="product in products" :key="product.id">
            <div :class="getSpecialBackground(product.id)" class="page-table-row-container">
              <div class="page-table-row">
                <div class="page-table-desc-column img-width">
                  <div class="page-table-header-mobile"></div>
                  <cc-logo :src="product.cover_url" @click="() => select_file(product)" />
                  <input style="display:none;"
                         type="file"
                         :ref="'cover-file-' + product.id"
                         @change="(evt) => update_cover(evt, product)">
                </div>
                <div class="page-table-desc-column id-width">
                  <div class="page-table-header-mobile">Código Ean</div>
                  {{ product.ean }}
                </div>
                <div class="page-table-desc-column name-width">
                  <div class="page-table-header-mobile">Nome</div>
                  {{ product.name }}
                </div>
                <div class="page-table-desc-column package-width">
                  <div class="page-table-header-mobile">Embalagem</div>
                  {{ product.packing.unit }} {{ product.packing.quantity }}
                </div>
                <div class="page-table-desc-column category-width">
                  <div class="page-table-header-mobile">Categoria</div>
                  {{ product.category ? product.category.name : '-' }}
                </div>
                <div class="page-table-desc-column provider-width">
                  <div class="page-table-header-mobile">Fornecedor</div>
                  {{ product.provider.name }}
                </div>
                <div class="page-table-desc-column status-width">
                  <div class="page-table-header-mobile">Status</div>
                  <toggle-button v-model="product.status"
                                 @change="toggle_status(product)"
                                 :sync="true"
                                 :color="'#ff7110'"
                                 :width="60"
                                 :height="30" />
                </div>
                <div class="page-table-desc-column actions-width">
                  <div class="page-table-header-mobile">Ações</div>
                  <span @click="edit(product)" class="material-icons-outlined edit-icon" title="Editar produto">
                    edit
                  </span>
                  <span @click="remove(product)" class="material-icons-outlined remove-icon" title="Remover produto">
                    delete
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <tr style="display: flex; !important" v-show="total_items > items_by_page_value">
        <td style="margin: 0 auto;" colspan="5">
          <cc-paginacao :key="'pag'"
                        classes="orange"
                        :items_by_page_value="items_by_page_value"
                        :total="total_items"
                        :page="filters.page"
                        :page_limit="11"
                        v-on:change="load($event)"/>
        </td>
      </tr>
      <ProductModal @close="is_product_modal_open=false"
                    v-if="is_product_modal_open"
                    :product="current_product" />
    </div>
    <!-- <div class="monitors">
      <div class="body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <form @submit.prevent="load()">
                            <div class="row py-3">
                                <div class="col col-3">
                                    <label for="" class="label-control">Buscar por nome</label>
                                    <cc-search v-model="filters.name" :noicon="true" />
                                </div>
                                <div class="col col-3">
                                    <label for="" class="label-control">Selecione um fornecedor</label>
                                     <cc-select-v2
                                        :tracker="'id'"
                                        :text="'name'"
                                        :searchable="true"
                                        :options="providers"
                                        @select="filters.page = 1"
                                        v-model="filters.provider" />
                                </div>
                                <div class="col col-2">
                                    <label for="" class="label-control">&nbsp;</label>
                                    <cc-checkbox
                                        class="mt-1"
                                        @change="(evt) => { filters.without_category = evt.target.checked;load() }"
                                        :label="'Sem Categoria'"
                                        :checked="filters.without_category" />
                                </div>
                                <div class="col col-1">
                                    <label for="" class="label-control">&nbsp;</label>
                                    <cc-button type="submit"
                                        class="w-100"
                                        :nolink="true"
                                        :classes="'fixed secondary'"
                                        :icon="'fa fa-search fa-lg'"/>
                                </div>
                                <div class="col col-2">
                                    <label for="" class="label-control">&nbsp;</label>
                                    <cc-button
                                        :nolink="true"
                                        type="button"
                                        :classes="'fixed danger-outline'"
                                        :icon="'far fa-trash-alt'"
                                        :content="'Limpar Filtros'"
                                        @click="filters={};load()" />
                                </div>
                                <div class="col col-1">
                                    <label for="" class="label-control">&nbsp;</label>
                                    <cc-button
                                        :classes="'fixed'"
                                        :icon="'fas fa-chevron-left'"
                                        :content="'Voltar'"
                                        :link="'/fornecedores'" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-12 col-12 p-4 shadow rounded">
                        <cc-loader v-show="is_loading" class="default" />
                        <table class="table" :class="{ hidden : is_loading }">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>CÓDIGO</th>
                                    <th>Nome</th>
                                    <th>Emb.</th>
                                    <th>Categoria</th>
                                    <th>Fornecedor</th>
                                    <th>Status</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="product in products" v-bind:key="product.id">
                                    <td>
                                        <cc-logo :src="product.cover_url" @click="() => select_file(product)" />
                                        <input
                                            style="display:none;"
                                            type="file"
                                            :ref="'cover-file-' + product.id"
                                            @change="(evt) => update_cover(evt, product)">
                                    </td>
                                    <td>{{ product.ean }}</td>
                                    <td>{{ product.name }}</td>
                                    <td>{{ product.packing.unit }} {{ product.packing.quantity }}</td>
                                    <td>{{ product.category ? product.category.name : '-' }}</td>
                                    <td>{{ product.provider.name }}</td>
                                    <td>
                                        <toggle-button
                                            v-model="product.status"
                                            @change="toggle_status(product)"
                                            :sync="true"
                                            :color="'#ff7110'"
                                            :height="20" />
                                    </td>
                                    <td class="text-center actions">
                                        <i title="Remover produto"
                                            class="fa fa-trash"
                                            @click="remove(product)"></i>
                                        <i class="fa fa-pencil remove-btn"
                                            @click="edit(product)" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer clearfix not-print">
                    <cc-paginacao
                        :key="'pag'"
                        classes="orange"
                        :items_by_page_value="items_by_page_value"
                        :total="total_items"
                        :page="filters.page"
                        :page_limit="11"
                        v-on:change="load($event)"></cc-paginacao>
                </div>
            </div>
            <ProductModal
                @close="is_product_modal_open=false"
                v-if="is_product_modal_open"
                :product="current_product" />
        </div>
    </div> -->
</template>
<script>

import Paginacao from "@/components/cliente/base-components/Pagination";
import ProductService from "@/services/v3/providers/product.service";
import ProviderServiceV1 from "@/services/ProviderService";
import ProviderServiceV3 from "@/services/v3/provider.service";
import ProductModal from "@/modals/provider/products/product.modal"
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import UserService from "@/services/v1/user.service"
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import Button from '@/components/ui/buttons/StandardButton.vue'

export default {
    mixins: [ loaderMixin ],
    components: {
        ccPaginacao: Paginacao,
        ProductModal,
        StandardInput,
        Button,
    },
    data(){
        return{
          image:"/images/search.png",
            is_loading: false,
            total_items: 1,
            items_by_page_value: 15,
            svc: new ProductService(),
            svc_v1: new ProviderServiceV1(),
            svc_v3: new ProviderServiceV3(),
            user_svc: new UserService(),
            products: [],
            states: [],
            providers: [],
            is_product_modal_open: false,
            filters: {
                page: 1,
                provider: {
                  id: undefined,
                  name: undefined,
                },
                without_category: 0,
            },
            current_product: null,
            breadcrumbs: [
                {
                    name: "Fornecedores",
                    route: 'sellers'
                }
            ],
            providersList: [],
            productsWithoutCategoriesIsChecked: false,
        }
    },
    methods: {
        filterProductsWithoutCategories() {
          this.productsWithoutCategoriesIsChecked = true;
          this.filters.without_category = 1;

          this.load();
        },
        removeProductsWithoutCategories() {
          this.productsWithoutCategoriesIsChecked = false;
          this.filters.without_category = 0;

          this.load();
        },
        setNameValue(value){
		      this.filters.name = value;
		      this.load();
	    	},
        setProviderValue (value) {
          this.filters.provider.id = value.id;
          this.filters.provider.name = value.name;
		      this.load();
        },
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        toggle_status(product) {
            if(product.status) {
                this.svc.activate(product.id)
            } else {
                this.svc.deactivate(product.id).then(() => {
                    // product.status = !product.status
                })
            }
        },
        remove(product) {
            this.confirm_action({
                message: `Confirma remoção do produto?`,
                subtitle: `O produto ${product.name} será removido!`,
                callback: () => this.svc.remove(product.id).then(() => this.load())
            })
        },
        manage_permissions(provider) {
            this.is_provider_permissions_modal_open = true
            this.current_provider = provider
        },
        select_file(provider) {
            this.$refs['cover-file-'+provider.id][0].click()
        },
        update_cover(evt, product) {
            let file = evt.target.files[0];
            this.svc.upload_cover(product.id, file).then(response => {
                this.load()
            })
        },
        close_modal(reload = false) {
            this.is_provider_modal_open = false
            this.current_provider = null
            if(reload) {
                this.load()
            }
        },
        edit(product) {
            this.current_product = product
            this.is_product_modal_open = true
        },
        show_users(provider) {
            this.is_provider_users_modal_open = true
            this.current_provider = provider
        },

        show_associate_categories(provider) {
            this.is_provider_associate_modal_open = true
            this.current_provider = provider
        },
        load_region_data() {
			return this.user_svc.region_data().then(response => response.data).then(data => {
				this.states = data.estados
			})
		},
        load(new_page = this.filters.page) {
            this.filters.page = new_page;
            this.is_loading = true;
            let params = {
                name: this.filters.name,
                page: this.filters.page,
                without_category: this.filters.without_category ? 1 : 0,
                provider: (this.filters.provider)? this.filters.provider.id : null
            }
            this.svc.load(params)
            .then(response => response.data)
            .then(response => {
                this.products = response.data;
                this.total_items = response.meta.total;
                this.is_loading = false;
            }).catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        load_providers(){
            this.svc_v3.load_by_products()
            .then(response => response.data)
            .then(response => {
              this.providers = response;
              response.forEach((data) => {
                this.providersList.push({
                  text: data.name,
                  value: data,
                })
              })
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        }
    },
    created() {
      this.load();
      this.load_region_data()
      this.load_providers()
      this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_tables';

    .seller-icon {
        color: $blue1;
        padding: 0 0.6rem;
        font-size: 1rem;
        &:hover {
            cursor: pointer;
        }
    }

    .rate {
        background-color: white;
        padding: 0.5rem 0.5rem;
        border-radius: 5px;
        color: black;
        cursor: pointer;
        i {
            font-size: 1.3rem;
            color: $yellow1;
        }
        &:hover {
            color: white;
            background-color: $blue1;
            i {
                color: white;
            }
        }
    }

    .actions {
        justify-content: center;
        i {
            font-size: 1.2rem;
            cursor: pointer;
            &.fa-trash {
                color: red;
            }
            &.fas.fa-user-lock {
                color: lightseagreen;
            }
        }
    }
    .col-btn-clear{
        max-width: 175px
    }

.monitors {
  font-size: 1vw;
  margin-right: 4em;
}

// New CSS
.page-container {
  font-size: 1vw;
  margin-right: 4em;
}
.page-route-select {
  display: flex;
  align-items: center;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color: #ff7110;
  margin-right: 20px;
}
.page-title {
  font-weight: 600;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 45px;
}
.orders-page__top-actions-container {
  display: flex;
  flex-direction: row-reverse;
  padding: 2em 0em;
}
.orders-page__actions-container {
  display: flex;
  justify-content: space-between;
  padding: 1em 0em;
}
.orders-page__content-inputs {
  width: 35vw;
}
.lastbuydate_wrapper {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 20px;
}
.products-page__checkbox-wrapper {
  display: flex;
}
/* The container */
.products-page__checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #898989;
}

/* Hide the browser's default checkbox */
.products-page__checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2.5px solid #ff7110;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.products-page__checkbox-wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.products-page__checkbox-wrapper input:checked ~ .checkmark {
  background-color: #ff7110;
  border: 1px solid #ff7110;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.products-page__checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.products-page__checkbox-wrapper .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.lastbuydate_title {
  position: relative;
  top: 2px;
  font-size: 1.5vw;
}
.page-selected-filters-container {
  display: flex;
  margin: 1em 0em;
}
.page-selected-filters {
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-selected-filters-text {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #FF7110;
  margin-right: 5px;
}
.page-selected-filters-icon {
  color: #FF7110;
  cursor: pointer;
}
.page-table-container {
  margin: 2em 0em;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.page-table-row {
  display: flex;
  font-weight: 300;
  font-size: 1.0em;
  color: #605F5F;
}
.page-table-desc-column {
  padding: 0.5em 0.7em;
}
.img-width {
  width: 6.5%;
}
.id-width {
  width: 8.5%;
}
.name-width {
  width: 26.5%;
}
.package-width {
  width: 12.5%;
}
.category-width {
  width: 12.5%;
}
.provider-width {
  width: 12.5%;
}
.status-width {
  width: 12.5%;
}
.actions-width {
  width: 8.5%;
}
.page-table-line-special {  
  background: #F7F7F7;
}
.page-table-header-mobile {
  display:none
}
.edit-icon {
  color: #505050;
  cursor: pointer;
}
.remove-icon {
  margin-left: 10px;
  color: #CB4243;
  cursor: pointer;
}

@media only screen and (max-width: 1000px){
  .page-table-header{
    display: none;
  }
  .page-container {
    font-size: 1.5vw;
    margin-right: 5vw;
  }
  .page-table-row{
    flex-direction: column;
    font-size: 1.2em;
  }
  .page-table-desc-column{
    display: flex;
    justify-content: space-between;
    text-align: end;
  }
  .checkbox-width {
    width: 100%;
  }
  .ean-code-width {
    width: 100%;
  }
  .gtin-code-width {
    width: 100%;
  }
  .product-name-width {
    width: 100%;
  }
  .package-width {
    width: 100%;
  }
  .client-width {
    width: 100%;
  }
  .last-date-width {
    width: 100%;
  }
  .edit-width {
    width: 100%;
  }
  .page-table-header-mobile{
    display: block;
    font-weight: 600;
    font-size: 1.1em;
    color: #505050;
    text-align: left;
  }
}
@media only screen and (max-width: 800px) {
  .page-container {
    font-size: 2.5vw;
  }
  .products-page__inputs-container{
    flex-direction: column;
    gap: 2vh;
  }
  .products-page__inputs-input{
    width: 100%;
  }
  .products-page__actions-container{
    flex-direction: column;
    gap: 2vh;
  }
  .products-page__right-content{
    flex-direction: column;
    width: 100%;
    gap: 2vh;
  }
  .products-page__left-content{
    flex-direction: column;
    gap: 2vh;
  }
  .action-buttons{
    margin-left: 0;
    width: 100%;
  }
  .page-filters__right-select-wrapper{width: 100vw;}
  .lastbuydate_wrapper{padding-top: 0;}
}
@media only screen and (max-width: 550px) {
  .page-container {
    font-size: 3.5vw;
  }
}
</style>
