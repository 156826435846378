<style lang="scss" scoped>
@import "./product.modal";

.modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    overflow-y: auto;
    font-size: 1vw;
}
.modal-content-container {
    background-color: #fff;
    border-radius: 12px;
    width: 75vw;
    min-height: 85vh;
}
.new-provider-header {
    display: flex;
    justify-content: space-between;
    background-color: #FF7110;
    font-weight: 500;
    font-size: 2em;
    color: #FFFFFF;
    align-items: center;
    padding: 3vh 2vw;
    border-radius: 10px 10px 0px 0px;
}
.new-product__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
}
.new-provider-close-icon {
    cursor: pointer;
    font-size: 1.3em;
}
.form-container {
    padding: 2em;
}
.edit-product-input-width {
    width: 30vw;
}
.inputs-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0.25em 0em;
}
.select-input-width {
    width: 30vw !important;
}
.input-title {
  display: block;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.save-button-wrapper {
    display: flex;
    flex-direction: row-reverse;
    padding: 1em 0em;
}

</style>

<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="new-provider-header">
        <span class="new-product__header-title">Editar Produto</span>
        <span v-on:click="close" class="material-icons new-provider-close-icon">cancel</span>
      </div>
      <div class="form-container">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form action="" @submit.prevent="handleSubmit(save)">
            <div class="inputs-wrapper">
              <div>
                <ValidationProvider rules="required" v-slot="v">
                  <span class="input-title">Descrição: <span style="color: #CB4243; font-size: 24px" title="Obrigatório">*</span></span>
                  <cc-input style="width: 71vw;"
                            v-model="product.name"
                            placeholder="Descrição do seu produto." />
                  <span class="error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="inputs-wrapper">
              <ValidationProvider rules="required" v-slot="v">
                <span class="input-title">Categoria: <span style="color: #CB4243; font-size: 24px" title="Obrigatório">*</span></span>
                <cc-select-v2 class="select-input-width"
                              :tracker="'id'"
                              :text="'name'"
                              :options="categories"
                              v-model="product.category" />
                <span class="error">{{ v.errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider rules="required" v-slot="v">
                <span class="input-title">Unidade: <span style="color: #CB4243; font-size: 24px" title="Obrigatório">*</span></span>
                <cc-select-v2 class="select-input-width"
                              :tracker="'value'"
                              :text="'name'"
                              v-model="wrap" :options="wraps" />
                <span class="error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="inputs-wrapper">
              <ValidationProvider rules="required" v-slot="v">
                <span class="input-title">Código: <span style="color: #CB4243; font-size: 24px" title="Obrigatório">*</span></span>
                <cc-input class="edit-product-input-width"
                          placeholder="Digite uma Referência"
                          v-model="product.ean" />
                <span class="error">{{ v.errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider rules="required" v-slot="v">
                <span class="input-title">Qtd. Embalagem: <span style="color: #CB4243; font-size: 24px" title="Obrigatório">*</span></span>
                <cc-input class="edit-product-input-width" v-model="product.packing.quantity" placeholder="Digite uma Quantidade"/>
                <span class="error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div>
            <toggle-button
                v-model="product.status"
                :color="'#ff7110'"
                :height="40"
                :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
                :width="95" />
          </div>
            <div class="save-button-wrapper">
              <div>
                <span class="py-4 text-center text-danger">{{ error_messages }}</span>
              </div>
              <Button style="margin-left: 20px;" :action="save" text="Salvar"/>
              <Button :action="close" text="Cancelar"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
    <!-- <cc-modal :modal="modal" @close="close">
        <cc-loader v-show="loading"/>
        <div slot="header-actions">
            <toggle-button
                v-model="product.status"
                :color="'#ff7110'"
                :height="30"
                :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
                :width="75" />
        </div>
        <div slot="body" class="container-fluid">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form action="" @submit.prevent="handleSubmit(save)">
                    <div class="row">
                        <div class="col-md-12 col-lg-12">
                            <label class="label-control">Descrição:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input
                                    v-model="product.name"
                                    placeholder="Descrição do seu produto.Exemplo:Macarrão Instantâneo Sabor Carne" />
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col-md-6 col-lg-6">
                            <label>Categoria:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-select-v2
                                    :tracker="'id'"
                                    :text="'name'"
                                    :options="categories"
                                    v-model="product.category" />
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div  class="col-md-6 col-lg-6">
                            <label>Código:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input
                                    placeholder="Digite uma Referência"
                                    v-model="product.ean" />
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col">
                            <label class="label-control inputCadastroProduto inputLarg2">Unidade:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-select-v2
                                    :tracker="'value'"
                                    :text="'name'"
                                    v-model="wrap" :options="wraps" />
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <label class="label-control">Qtd.Embalagem:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input v-model="product.packing.quantity" />
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div v-if="error_messages" class="col-md-12 col-lg-12">
                            <h4 class="py-4 text-center text-danger">{{ error_messages }}</h4>
                        </div>
                    </div>
                    <button id="submit-product" class="hidden"></button>
                </form>
            </ValidationObserver>
        </div>
    </cc-modal> -->
</template>

<script>

import ProductService from "@/services/v3/providers/product.service";
import CategoryService from '@/services/v3/providers/category.service';
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Button from "@/components/ui/buttons/StandardButton";

export default {
    props: {
        product: {
            type: Object,
            required: true
        },
        to_request: {
            type: Boolean,
            default: false
        }
    },
    components: {
		ValidationProvider,
		ValidationObserver,
        Button,
    },
    data() {
        return {
            edit_internal_code_allowed: false,
            modal: {
                title: "produto",
                submit: () => document.getElementById("submit-product").click(),
                submit_text: this.product.id ? 'Atualizar' : 'Salvar',
                subtitle: 'Crie ou altere informações do produto',
                icon_title: 'far fa-edit',
                unpermitted_message: "Aceite o termo de consentimento para continuar.",
                permitted: true,
                style: {
                    width: "50%"
                }
            },
            wraps: [
                { name: 'Unidade', value: 'UN' },
                { name: 'Caixa', value: 'CX' },
                { name: 'Fardo', value: 'FD' },
                { name: 'Quilo', value: 'KG' },
                { name: 'Pacote', value: 'PT' },
                { name: 'Duzia', value: 'DZ' }
            ],
            svc: new ProductService(),
            cat_svc: new CategoryService(),
            loading: false,
            wrap: null,
            qtdEmbalagemModal: 1,
            error_messages: '',
            stores: [],
            categories: [],
            product_service: new ProductService()
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        load_categories() {
            return this.cat_svc.load_categories().then((response) => response.data).then((data) => {
                this.categories = data
                this.product.category = this.categories.find(cat => this.product.category && cat.id == this.product.category.id)
            })
        },
        save() {
            this.product.packing.unit = this.wrap.value
            this.svc.update(this.product.id, this.product).then(res => res.data).then(()=>{
                this.loading = false;
                if(this.product.id) {
                    this.$swal.fire("Atualizado!", "Produto atualizado com sucesso.", "success");
                } else {
                    this.$swal.fire("Inserido!", "Produto inserido com sucesso.", "success");
                }
                this.$emit('reload')
                this.close()
            }).catch((err)=>{
                if(err.response && err.response.data && err.response.data.errors) {
                    let errors = err.response.data.errors
					this.error_messages = Object.values(errors).flat()[0]
				} else {
                    this.error_messages = 'Ops! Houve um erro ao salvar o produto, tente novamente mais tarde!'
				}
                this.loading = false
                ErrorHandlerService.handle(error, this.$store);
            })
        },
        process_packing() {
            if(this.product.packing) {
                this.wrap = this.wraps.find(w => w.value == this.product.packing.unit)
            }
        }
    },
    watch: {
        'wrap.value' : function(new_val) {
            if(new_val == 'UN') {
                this.qtdEmbalagemModal = 1
            } else if(new_val == 'DZ') {
                this.qtdEmbalagemModal = 12
            }
        },
        'qtdEmbalagemModal': function(new_val) {
            if(new_val && new_val.match(/[^0-9]/g)) {
                this.qtdEmbalagemModal = this.qtdEmbalagemModal.replace(/[^0-9]/g, '')
            }
        }
    },
    mounted() {
        this.load_categories()
        this.process_packing()
    }
}
</script>
